const sharpenImage = (cv: any, source: HTMLImageElement | HTMLCanvasElement, dest?: HTMLCanvasElement): Promise<HTMLCanvasElement> => {
    return new Promise((resolver) => {
        dest = dest || (document.createElement("canvas") as HTMLCanvasElement);
        dest.width = source?.width;
        dest.height = source?.height;
        // Assuming you have an input image with id 'inputImage'
        if (source && dest) {
            let sc = cv.imread(source);
            let dt = new cv.Mat();
            // Define the sharpening kernel using matFromArray
            // const sharpenKernelData = [-1, -1, -1, -1, 9, -1, -1, -1, -1];
            const sharpenKernelData = [0, -1, 0, -1, 5, -1, 0, -1, 0];
            // const sharpenKernelData = [-0.5, -0.5, -0.5, -0.5, 5, -0.5, -0.5, -0.5, -0.5];
            const kernel = cv.matFromArray(3, 3, cv.CV_32F, sharpenKernelData);
            // Apply the kernel
            cv.filter2D(sc, dt, cv.CV_8U, kernel);
            // Assuming you have an output canvas with id 'outputCanvas'
            cv.imshow(dest, dt);
            sc.delete();
            dt.delete();
            kernel.delete();
            resolver(dest);
        } else {
            // empty because previously a console statement
            // status message index = 14
        }
    });
};

export default sharpenImage;
